// These are just arrays to be used by the form.
export const teachingStatuses = [
  'Currently Teaching MongoDB',
  'Interested in Teaching MongoDB',
  'Just Curious',
];

export const institutionTypes = [
  'Bootcamp',
  'High School',
  'University / College',
];

export interface Registration {
  firstName: string;
  lastName: string;
  institutionName: string;
  institutionType: string;
  email: string;
  facultyProfile: string;
  agreedToEmails: boolean;
  location: string;
  courseName: string;
  courseSyllabus: string;
  teachingStatus: string;
}
