import axios from 'axios';
import { z } from 'zod';
import countryList from 'react-select-country-list';

import { institutionTypes, teachingStatuses } from 'lib/registration';

import { AttachmentsType, FieldInterface } from './types';

// CONSTANTS
const DEFAULT_CHAR_LIMIT = 50;

export const FORM_INIT_STATE = {
  isOpen: false,
  fields: [],
  multiFileUpload: false,
  texts: {
    title: '',
    postSubmissionTitle: '',
    postSubmissionDescription: '',
    button: '',
  },
  submitForm: () => null,
};

export const EDUCATOR_PROGRAM_FORM_TEXTS = {
  title: 'MongoDB for Educators Program Application',
  postSubmissionTitle: 'Thanks for applying to MongoDB for Educators!',
  postSubmissionDescription:
    'We will review your application and email you within 5-7 business days.',
  button: 'Submit my Application',
};

export const EDUCATOR_PROGRAM_FORM_FIELDS: Array<FieldInterface> = [
  {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    component: 'text-input',
    validators: [isRequired(), charLimit()],
  },
  {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    component: 'text-input',
    validators: [isRequired(), charLimit()],
  },
  {
    name: 'email',
    label: 'Your School or Institution Email',
    type: 'email',
    component: 'text-input',
    validators: [isRequired(), emailPattern(), charLimit()],
  },
  {
    name: 'teachingStatus',
    label: 'Teaching Status',
    component: 'select',
    options: teachingStatuses,
    validators: [isRequired()],
  },
  {
    name: 'facultyProfile',
    label: 'Faculty profile/Google scholar profile URL',
    component: 'text-input',
    validators: [urlPattern()],
  },
  {
    name: 'institutionName',
    label: 'Name of Institution',
    type: 'text',
    component: 'text-input',
    validators: [isRequired(), charLimit()],
  },
  {
    name: 'institutionType',
    label: 'Institution Type',
    component: 'select',
    options: institutionTypes,
    validators: [isRequired()],
  },
  {
    name: 'location',
    label: 'Country',
    component: 'select',
    options: countryList().getLabels() || [],
    validators: [isRequired()],
  },
  {
    name: 'courseName',
    label: 'Course Name',
    type: 'text',
    component: 'text-input',
    validators: [isRequired(), charLimit()],
  },
  {
    name: 'attachments',
    label: 'Course Syllabus',
  },
  {
    name: 'agreedToEmails',
    label: 'I agree to receive emails from MongoDB, Inc.',
    component: 'checkbox',
    validators: [isRequired()],
  },
];

export const PHD_FELLOWSHIP_FIELDS: Array<FieldInterface> = [
  {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    component: 'text-input',
    validators: [isRequired(), charLimit()],
  },
  {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    component: 'text-input',
    validators: [isRequired(), charLimit()],
  },
  {
    name: 'address',
    label: 'Address',
    type: 'text',
    component: 'text-input',
    validators: [isRequired(), charLimit()],
  },
  {
    name: 'apartment',
    label: 'Apartment, Suite, etc. (optional)',
    type: 'text',
    component: 'text-input',
    validators: [charLimit()],
  },
  {
    name: 'city',
    label: 'City',
    type: 'text',
    component: 'text-input',
    validators: [isRequired(), charLimit()],
  },
  {
    name: 'state',
    label: 'State',
    component: 'select',
    options: [
      'Alabama',
      'Alaska',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'Florida',
      'Georgia',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
    ],
    validators: [isRequired()],
  },
  {
    name: 'country',
    label: 'Country',
    component: 'select',
    options: countryList().getLabels() || [],
    validators: [isRequired()],
  },
  {
    name: 'zipcode',
    label: 'ZIP Code',
    type: 'text',
    component: 'text-input',
    validators: [isRequired(), charLimit()],
  },
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    component: 'text-input',
    validators: [isRequired(), emailPattern(), charLimit()],
  },
  {
    name: 'university',
    label: 'University',
    type: 'text',
    component: 'text-input',
    validators: [isRequired(), charLimit()],
  },
  {
    name: 'program',
    label: 'Degree Program',
    type: 'text',
    component: 'text-input',
    validators: [isRequired(), charLimit()],
  },
  {
    name: 'advisorName',
    label: 'Doctoral Advisor Name',
    type: 'text',
    component: 'text-input',
    validators: [isRequired(), charLimit()],
  },
  {
    name: 'advisorEmail',
    label: 'Doctoral Advisor Email',
    type: 'email',
    component: 'text-input',
    validators: [isRequired(), emailPattern(), charLimit()],
  },
  {
    name: 'topic',
    label: 'Research Topic',
    type: 'text',
    component: 'text-input',
    validators: [isRequired(), charLimit(200)],
  },
  {
    name: 'summary',
    label: 'Research Summary',
    type: 'text',
    component: 'text-area',
    validators: [isRequired(), charLimit(355)],
  },
  {
    name: 'attachments',
    label: 'Attachments:',
  },
  {
    name: 'agreedToEmails',
    label: 'I agree to receive emails from MongoDB, Inc.',
    component: 'checkbox',
  },
];

const DISALLOWED_EMAIL_DOMAINS = [
  'gmail.com',
  'icloud.com',
  'outlook.com',
  'yahoo.com',
  'yandex.ru',
  'proton.me',
  'zohomail.com',
  'rocketmail.com',
  'hotmail.com',
];

// Helper functions
export function isRequired() {
  return function (value: string): string {
    return !value || value === '' ? 'This field is required' : '';
  };
}

export function emailPattern(checkDomains = true) {
  return function (value: string): string {
    if (value) {
      try {
        z.string().email().parse(value);
      } catch {
        return 'Please enter a valid email address';
      }
      if (
        checkDomains &&
        DISALLOWED_EMAIL_DOMAINS.find(domain => value.split('@')[1] === domain)
      ) {
        return 'School or Institution email is required';
      }
      return '';
    }
    return '';
  };
}

export function charLimit(limit: number = DEFAULT_CHAR_LIMIT) {
  return function (str: string): string {
    if (str) {
      try {
        z.string().max(limit).parse(str);
      } catch {
        return `Input is too long. Maximum characters allowed: ${limit}`;
      }
      return '';
    }
    return '';
  };
}

function urlPattern() {
  return function (value: string): string {
    if (value) {
      try {
        z.string().url().parse(value);
      } catch {
        return 'Please enter a valid Web URL';
      }
      return '';
    }
    return '';
  };
}

export async function submitEducatorProgramForm(
  formData: FormData,
  attachments: AttachmentsType
) {
  let syllabus;
  if (attachments.docs) {
    syllabus = attachments.docs[0];
  } else {
    const isInvalidUrl = urlPattern();
    syllabus = attachments.urls[0].value;
    if (isInvalidUrl(syllabus)) {
      throw Error('Please enter a valid URL for attachments.');
    }
  }

  // append file or text from CourseSyllabus component to formData since it doesn't come thru in form callback from Flora
  formData.append('courseSyllabus', syllabus);

  try {
    const response = await axios.post(
      '/academia/api/educator-program/registration',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response;
  } catch (e: any) {
    // Give generic UI error.
    throw Error(
      'There was an error submitting your request. Please try again.'
    );
  }
}

export async function submitPhdFellowshipForm(
  formData: FormData,
  attachments: AttachmentsType
) {
  if (attachments.urls.length) {
    const isInvalidUrl = urlPattern();
    attachments.urls.forEach(({ value }) => {
      if (isInvalidUrl(value)) {
        throw Error('Please enter valid URLs for attachments.');
      }
      formData.append('urls', value);
    });
  } else {
    formData.set('urls', '');
  }

  if (attachments.docs) {
    for (let i = 0; i < attachments.docs.length; i++) {
      formData.append('uploads', attachments.docs[i]);
    }
  } else {
    formData.set('uploads', '');
  }

  try {
    const response = await axios.post(
      '/academia/api/phd-fellowship/application',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response;
  } catch (e: any) {
    // Give generic UI error.
    throw Error(
      'There was an error submitting your request. Please try again.'
    );
  }
}
